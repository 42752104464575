/* eslint-disable no-console */
import { APINinjaConfig } from '@kakawajazz/fruit-ninja';
import { WebAppConfig } from 'entities/good-luck/web-app-config';
import { User } from 'entities/good-luck/user';
import { Score } from 'entities/ninja/score';
import { PostActivityCommand } from 'entities/good-luck/post-activity-command';
import { GOOD_LUCK_BASE_URL, NINJA_BASE_URL } from 'shared/constants';
import { LocaleCode } from '../shared/i18n';
import { BaseService } from './BaseService';
import { gameDataService } from './GameDataService';


interface ICredentials {
    URL: string;
}


class MetricsAPIService extends BaseService {
    public setCredentials (credentials: ICredentials): void {
        this._credentials = credentials;
    }

    public async sendMetric (route: string, data: Object) {
        return this.post(route, data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getGamesData (params?: { lang: string }) {
        return this.get('/games/', params).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getSurveyData (lang: string) {
        return this.get(`/survey/?lang=${ lang }`).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getSurveyId (data: { survey: number }) {
        return this.post('/survey/response/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async sendSurveyData (data: { question: number; body: string; response: number | null }) {
        return this.post('/survey/response/answers/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getConstructorSurveyData (lang: string) {
        return this.get(`/constructor/survey/?lang=${ lang }`).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getConstructorSurveyId (data: { survey: number }) {
        return this.post('/constructor/survey/response/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async sendConstructorSurveyData (data: {
        question: number
        option: string | number
        response: number | null
    }) {
        return this.post('/constructor/survey/response/answers/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getSurveyIqosData (lang: string) {
        return this.get(`/survey-iqos/?lang=${ lang }`).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getSurveyIqosId (data: { survey: number }) {
        return this.post('/survey-iqos/response/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async sendSurveyIqosData (data: {
        question: number
        option: string | number
        response: number | null
    }) {
        return this.post('/survey-iqos/response/answers/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async sendLoyaltyData (data: {
        question: string
        answer: string
        response: number | null
    }) {
        return this.post('/loyalty/response/answers/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getLoyaltyData (lang: string) {
        return this.get(`/loyalty/?lang=${ lang }`).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async getLoyaltyId (data: { loyalty: number }) {
        return this.post('/loyalty/response/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async checkLoyalty (data: { code: string }) {
        return this.post('/loyalty/check/', data);
    }

    public async changeLoyalty (data: { new_game_id: string }) {
        return this.post('/loyalty/change-brand/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async postTelegram () {
        const token = await gameDataService.gameToken;
        return this.post(`/constructor/survey/telegram/?token=${ token }`, { token }).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    public async sendParliamentUZData (data: { answer_number: number }) {
        return this.post('/parliament-uz/response/', data).catch((error) => {
            console.log('🚀 ~ error: ', error);
        });
    }

    async getGoodLuckConfig () {
        const response = await this.get<any>(`${ GOOD_LUCK_BASE_URL }/config/`);
        return response.data;
    }

    async getGoodLuckWebAppConfig (brand: User.Brand, city?: string) {
        const cityParameter = city ? `?city=${ city }` : '';
        const response      = await this.get<WebAppConfig.Model>(`${ GOOD_LUCK_BASE_URL }/config/` + brand + cityParameter);
        return response.data;
    }

    async getGoodLuckUser (userId: string) {
        const response = await this.get<User.Model>(`${ GOOD_LUCK_BASE_URL }/users/` + userId);
        return response.data;
    }

    async postGoodLuckActivity (command: PostActivityCommand) {
        const response = await this.post<User.Model>(`${ GOOD_LUCK_BASE_URL }/users/activities/`, command);
        return response.data;
    }

    async postGoodLuckSpin (user_id: string) {
        const response = await this.post<User.Model>(`${ GOOD_LUCK_BASE_URL }/spins/${ user_id }`, {});
        return response.data;
    }

    async postNinjaWebAppConfig (language: LocaleCode) {
        const response = await this.post<APINinjaConfig.Model>(`${ NINJA_BASE_URL }/config/${ language }/`, {});
        return response.data;
    }

    async postNinjaScore (score: number) {
        const response = await this.post<Score.Model>(`${ NINJA_BASE_URL }/scores/`, { score });
        return response.data;
    }
}


export const MetricsService = new MetricsAPIService();
